import React from "react";

function Footer() {
    return (
        <div className="footer">
            <div className="h-full flex justify-center items-center">{/* <div>footer</div> */}</div>
        </div>
    );
}

export default Footer;
